import styled from 'styled-components'

const input = styled.input`
  font-size: 1.25rem;
  padding: 10px 15px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  display: block;
  width: 100%;
  max-width: 100%;
  appearance: none;

  &:hover {
    border-color: rgba(0, 0, 0, 0.87);
  }
`

export default input
