import styled from 'styled-components'

const Heading = styled.h1`
  font-size: 32px;
  line-height: 42px;
  font-weight: 700;
  letter-spacing: 0.00294118em;
  padding-bottom: 0.875rem;
  color: #005060;
  margin: 0;

  @media (min-width: 50rem) {
    font-size: 4.25rem;
    line-height: 4.1875rem;
  }
`

export default Heading
