import styled from 'styled-components'

const Container = styled.div`
  padding: 2rem 2rem 0 2rem;
  width: 500px;
  max-width: 80%;
  margin: 0 auto;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 2rem;
    height: 2rem;
    border-top: 0.25rem solid #005060;
    border-left: 0.25rem solid #005060;
  }
`

export default Container
