import React, { useState } from 'react'
import styled from 'styled-components'
import { updatePhoneNumber } from '../api'
import { Alert, Button, Form, Input, Paragraph } from '../styled'
import { ArrowRight, CircleNotch } from './Icons'

import UpdatePhoneNumberFormProps from './interfaces/UpdatePhoneNumberFormProps'

const InputWrapper = styled.div`
  display: flex;
`

export default function UpdatePhoneNumberForm({
  token,
}: UpdatePhoneNumberFormProps) {
  const [phoneNumber, setPhoneNumber] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [result, setResult] = useState<null | boolean>(null)

  const handlePhoneNumberChange = (
    event: React.FormEvent<HTMLInputElement>
  ) => {
    setPhoneNumber(event.currentTarget.value)
  }
  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (!loading) {
      setLoading(true)
      try {
        await updatePhoneNumber(token, phoneNumber)
        setResult(true)
      } catch (e) {
        // empty
      }
      setLoading(false)
    }
  }

  if (result) {
    return <Alert variant="success">Telefonnummret har uppdaterats!</Alert>
  }
  return (
    <Form onSubmit={onSubmit}>
      <Paragraph textAlign="center">
        Är telefonnummret felaktigt? <br />
        Skriv in rätt nummer:
      </Paragraph>
      <InputWrapper>
        <Input
          type="tel"
          value={phoneNumber}
          onChange={handlePhoneNumberChange}
          style={{ marginRight: 10 }}
        />
        <Button type="submit">
          {!loading ? <ArrowRight /> : <CircleNotch />}
        </Button>
      </InputWrapper>
      {result === false && (
        <Alert variant="error">Misslyckades uppdatera telefonnummret</Alert>
      )}
    </Form>
  )
}
