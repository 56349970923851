const { NODE_ENV, REACT_APP_INTEGRATION_URI } = process.env
const INTEGRATION_URI =
  NODE_ENV === 'production' && REACT_APP_INTEGRATION_URI
    ? REACT_APP_INTEGRATION_URI
    : ''

const request = (
  endpoint: string,
  method: string,
  body: object | Array<unknown>
): Promise<Response> => {
  const options: RequestInit = {
    method,
    headers: {
      'Content-Type': 'application/json',
    },
  }

  if (body) {
    options.body = JSON.stringify(body)
  }

  return fetch(`${INTEGRATION_URI}${endpoint}`, options)
}

export const verifyToken = async (token: string): Promise<string> => {
  const result = await request('/v1/reset-phone-number/validate/', 'POST', {
    token,
  })
  const text = await result.text()
  if (Math.floor(result.status / 100) > 2) {
    throw new Error(text)
  }
  return text
}

export const updatePhoneNumber = async (
  token: string,
  phoneNumber: string
): Promise<string> => {
  const result = await request('/v1/reset-phone-number/', 'POST', {
    token,
    phoneNumber,
  })
  const text = await result.text()
  if (Math.floor(result.status / 100) > 2) {
    throw new Error(text)
  }
  return text
}
