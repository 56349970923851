import React from 'react'
import { Link } from '../components'
import { Container, Heading, Paragraph } from '../styled'

export default function InvalidToken() {
  return (
    <Container>
      <Heading>Ogiltlig länk!</Heading>
      <Paragraph>
        Kontakta support om du behöver hjälp:{' '}
        <Link href="https://www.iboxen.se/kundtjanst/" target="_blank">
          Till kundtjänst
        </Link>
      </Paragraph>
    </Container>
  )
}
