import React from 'react'
import styled from 'styled-components'
import Link from './Link'
import Logo from './Logo'

const StyledHeader = styled.header`
  text-align: center;
  padding: 20px;
`

function Header() {
  return (
    <StyledHeader>
      <Link href="https://iboxen.se/">
        <Logo />
      </Link>
    </StyledHeader>
  )
}

export default Header
