import styled from 'styled-components'

const button = styled.button`
  background-color: #005060;
  color: #fff;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
`

export default button
