import React, { useEffect, useState, useMemo } from 'react'
import qs, { ParsedQs } from 'qs'
import jwt_decode from 'jwt-decode'
import { verifyToken } from '../api'
import { InvalidToken, UpdatePhoneNumber } from '.'
import { TokenData } from '../interfaces'
import MainMemoData from './interfaces/MainMemoData'
import { cleanPhoneNumber, wait } from '../utils/helpers'
import { Paragraph } from '../styled'
import { CircleNotch } from '../components/Icons'

export default function Main() {
  const [invalidToken, setInvalidToken] = useState<boolean | null>(null)
  const { token, phoneNumber }: MainMemoData = useMemo(() => {
    let t: string | ParsedQs | string[] | ParsedQs[] | undefined
    let data: TokenData | undefined
    try {
      t = qs.parse(window.location.search.substring(1)).token
      data = jwt_decode(String(t))
    } catch (e) {
      // empty
    }
    return {
      token: t && String(t),
      phoneNumber:
        data && data.phoneNumber && cleanPhoneNumber(data.phoneNumber),
    }
  }, [])

  useEffect(() => {
    if (token !== undefined) {
      // eslint-disable-next-line func-names, @typescript-eslint/no-extra-semi
      ;(async function () {
        const start = new Date().getTime()
        let result: string | undefined
        try {
          result = await verifyToken(token)
        } catch (e) {
          // empty
        }
        const done = new Date().getTime()
        await wait(700 - (done - start) || 0)
        setInvalidToken(result !== 'ok')
      })()
    } else {
      setInvalidToken(true)
    }
  }, [token])
  return (
    <>
      {invalidToken === null && (
        <Paragraph textAlign="center">
          <CircleNotch size="medium" spin="fast" />
        </Paragraph>
      )}
      {phoneNumber && token && invalidToken === false && (
        <UpdatePhoneNumber token={token} phoneNumber={phoneNumber} />
      )}
      {invalidToken && <InvalidToken />}
    </>
  )
}
