import React from 'react'
import styled from 'styled-components'
import { Container, Heading, Image, Paragraph } from '../styled'
import { Link, UpdatePhoneNumberForm } from '../components'

import UpdatePhoneNumberProps from './interfaces/UpdatePhoneNumberProps'

const FormWrapper = styled.div`
  max-width: 300px;
  margin: 0 auto;
`

const StrongParagraph = styled(Paragraph)`
  font-size: 1.3rem;
  font-weight: 700;
  color: #005060;
`

export default function UpdatePhoneNumber({
  token,
  phoneNumber,
}: UpdatePhoneNumberProps) {
  return (
    <>
      <Container>
        <Heading style={{ textAlign: 'center' }}>
          Kontrollera ditt telefonnummer!
        </Heading>
        <Paragraph>
          Du behöver använda samma telefonnummer, som när du e-handlade, för att
          kunna hämta ut ditt paket. Glöm inte att du behöver BankId för att
          hämta ut ditt paket. Om du angivit ett annat telefonnummer (än det
          nedan) så kan du enkelt byta det här:
        </Paragraph>
        <div style={{ textAlign: 'center', margin: '5rem 0' }}>
          <p>Numret vi registrerat för denna leverans är:</p>
          <p>{phoneNumber}</p>
        </div>
        <FormWrapper>
          <UpdatePhoneNumberForm token={token} />
        </FormWrapper>
        <Paragraph>
          Kom ihåg att om du vill att någon annan ska hämta ditt paket så byt
          inte telefonnummer utan använd funktionen i appen istället och dela ut
          behörighet till personen. Du hittar funktionen på ditt paket under
          paketets detaljer.
        </Paragraph>
      </Container>
      <Container style={{ marginTop: '5rem' }}>
        <Paragraph textAlign="center" style={{ marginTop: 0 }}>
          För att hämta ditt paket behöver du iBoxen-appen.
        </Paragraph>
        <StrongParagraph>
          Ladda ner appen från App Store eller Google Play
        </StrongParagraph>
        <Paragraph>
          <Link
            target="_blank"
            href="https://apps.apple.com/se/app/iboxen/id1553931951"
            rel="noreferrer"
          >
            <Image
              height="80"
              alt="Ladda ner från App Store"
              src="https://assets.qlocxiboxen.com/app-store-badge_sv_v1.png"
            />
          </Link>
          <Link
            target="_blank"
            href="https://play.google.com/store/apps/details?id=com.iboxenconsumerapp&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
            rel="noreferrer"
          >
            <Image
              height="80"
              alt="Ladda ner från Google Play Store"
              src="https://assets.qlocxiboxen.com/play-store-badge_sv_v1.png"
            />
          </Link>
        </Paragraph>
      </Container>
    </>
  )
}
