import React, { useMemo } from 'react'
import styled from 'styled-components'
import qs from 'qs'
import { Footer, Header } from './components'
import LayoutProps from './interfaces/LayoutProps'

const LayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: auto;
`

const MainWrapper = styled.main`
  min-height: 100%;
  height: atuo;
  margin: 40px 0;
`

function Layout({ children }: LayoutProps) {
  const isEmbeded = useMemo(
    () => 'embedded' in qs.parse(window.location.search.substring(1)),
    []
  )
  return (
    <LayoutWrapper>
      {!isEmbeded && <Header />}
      <MainWrapper>{children}</MainWrapper>
      {!isEmbeded && <Footer />}
    </LayoutWrapper>
  )
}

export default Layout
