const cleanPhoneNumber = (phoneNumber: string): string => {
  return [
    phoneNumber.slice(0, 3),
    Array(phoneNumber.slice(3, -3).length).fill('*').join(''),
    phoneNumber.slice(-3),
  ].join(' ')
}

const wait = (delay: number): Promise<void> =>
  new Promise((resolve) => {
    setTimeout(() => resolve(), delay)
  })

export { cleanPhoneNumber, wait }
