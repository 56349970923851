import React, { AnchorHTMLAttributes, useMemo } from 'react'
import { Link as StyledLink } from '../styled'

const useRel = ({ target, rel }: AnchorHTMLAttributes<HTMLElement>) => {
  return useMemo(() => {
    const shouldAppendNoReferrer =
      target === '_blank' && (!rel || !rel.includes('noreferrer'))
    return rel
      ? `${rel || ''}${shouldAppendNoReferrer ? ' noreferrer' : ''}`
      : undefined
  }, [rel, target])
}

function Link(props: AnchorHTMLAttributes<HTMLElement>) {
  const rel = useRel(props)
  return <StyledLink {...props} rel={rel} />
}

export default Link
