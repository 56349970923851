import React, { SVGProps } from 'react'

export default function Logo(
  props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>
) {
  return (
    <svg width="146" height="49" {...props} viewBox="0 0 146 49">
      <g>
        <path
          d="M48.751 36.644a9.966 9.966 0 003.046-7.288 9.786 9.786 0 00-3.003-7.254 10.093 10.093 0 00-7.361-2.966 10.138 10.138 0 00-7.404 2.966 9.806 9.806 0 00-3.003 7.254 9.879 9.879 0 003.003 7.246 10.15 10.15 0 007.361 3.008 9.818 9.818 0 007.361-2.966zm-12.75-7.288a5.363 5.363 0 011.58-3.802A5.347 5.347 0 0141.392 24a5.23 5.23 0 013.824 1.602 5.085 5.085 0 011.599 3.796 5.356 5.356 0 01-3.298 4.957 5.23 5.23 0 01-2.058.39 5.069 5.069 0 01-3.867-1.559 5.487 5.487 0 01-1.565-3.83M116.573 39.127v-9.754c0-6.82-2.922-10.232-8.765-10.237a7.927 7.927 0 00-4.865 1.33v-.847h-4.865V39.11h4.865v-9.737c0-3.39 1.464-5.356 4.383-5.356s4.374 1.95 4.374 5.356v9.754h4.873zm-21.609-7.771c.13-.642.195-1.295.195-1.95a10.282 10.282 0 00-2.375-6.686 10.24 10.24 0 00-13.106-2.264 10.268 10.268 0 00-4.473 5.504 10.287 10.287 0 00.158 7.096 10.266 10.266 0 004.714 5.299 10.24 10.24 0 0012.992-2.847l-3.892-2.923a5.34 5.34 0 01-4.97 2.085 5.333 5.333 0 01-4.252-3.314h15.01zm-5.076-3.898h-9.933a5.354 5.354 0 014.983-3.414 5.34 5.34 0 014.984 3.414"
          fill="rgba(8, 79, 97, 1)"
        />
        <path
          d="M0 0v9.881h4.865V4.873h136.27v39.254H0V49h146V0H0z"
          fill="rgba(8, 79, 97, 1)"
        />
        <path
          d="M24.283 23.178a5.927 5.927 0 002.97-5.492c0-4.88-3.893-7.805-8.758-7.805H9.73v29.246h9.73c5.838 0 9.73-2.924 9.73-8.771 0-4.034-1.853-6.212-4.916-7.178h.009zm-1.904-5.009c0 2.441-1.464 3.39-3.892 3.39h-3.892v-6.805h3.892c2.428 0 3.892.975 3.892 3.39v.025zm1.946 11.678c0 2.924-1.946 4.382-4.865 4.382h-4.857v-8.746h4.857c2.919 0 4.865 1.458 4.865 4.39M4.865 19.627H0v19.5h4.865v-19.5zM4.865 12.314H0v4.872h4.865v-4.872z"
          fill="rgba(8, 79, 97, 1)"
        />
        <path
          d="M57.906 39.305l-4.662-4.678 5.195-5.195-5.195-5.203 4.662-4.67 9.857 9.873-9.857 9.873z"
          fill="rgba(239, 66, 105, 1)"
        />
        <path
          d="M67.763 29.432l5.195-5.203-4.662-4.67-5.195 5.204 4.662 4.67-4.662 4.669 5.195 5.203 4.662-4.678-5.195-5.195z"
          fill="rgba(8, 79, 97, 1)"
        />
      </g>
    </svg>
  )
}
