import { HTMLAttributes } from 'react'
import styled, { css } from 'styled-components'

interface AlertProps extends HTMLAttributes<HTMLElement> {
  variant: 'error' | 'success'
}

const variants = {
  error: css`
    background-color: #fdeded;
    color: #5f2120;
    border-color: #5f2120;
  `,
  success: css`
    background-color: #edf7ed;
    color: #1e4620;
    border-color: #1e4620;
  `,
}

const alert = styled.p`
  padding: 10px 15px;
  border-radius: 3px;
  font-family: Work sans, sans-serif;
  ${(props: AlertProps) => variants[props.variant]}
`

export default alert
